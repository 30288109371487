import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';

import { Paper, Typography } from '@mui/material';
import BaseTextField from '../theme/partials/BaseTextField';
import BaseButton from '../theme/partials/BaseButton';
import { ToastContainer } from "react-toastify";
import { isEmpty } from '../helpers/Functions';
import { useParams } from 'react-router-dom';
import displayToast from '../theme/partials/DisplayToast';
import { loginGPT } from './crud/person.crud';
import { Image } from 'react-bootstrap';
import { DEFAULT_APPROVE_LOGO, DEFAULT_OLERN_TRANSPARENT_LOGO, INTLIGUIDE_LOGO, QUICK_ACCESS_BG } from '../models/Constants';
import TextView from '../theme/partials/Typography';

const GPTLogin = () => {
    const [emailSent, setEmailSent] = useState(false);
	const [isFullWidth, setIsFullWidth] = useState(false);
	const { library_code, conversation_link } = useParams();

	const [timer, setTimer] = useState(30);

	const [paperStyle, setPaperStyle] = useState({})

	useEffect(() => {
		const checkScreenWidth = () => {
		  if (window.innerWidth >= 1200) {
			setIsFullWidth(true);
			setPaperStyle({ px: 3, pt: 3, pb: 5, borderRadius: 5, width : 600, height: "70vh" });
		  } 
		  else {
			setIsFullWidth(false);
			setPaperStyle({ px: 2, pt: 2, pb: 3, borderRadius: 5, width : 600, height: "60vh" });
		  }
		};

		checkScreenWidth();

		window.addEventListener('resize', checkScreenWidth);
		return () => window.removeEventListener('resize', checkScreenWidth);
	  }, []);
	
	return (<>
		<ToastContainer />
		<div className="qa-wide-container p-3 w-100"
			style={{
				backgroundImage: "url(" + QUICK_ACCESS_BG + ")", 
				backgroundSize: 'cover', 
				backgroundPosition: 'center', 
				backgroundRepeat: 'no-repeat',
			}}
		>
			<div className='px-1 w-100 d-block'>
				<div className='d-flex w-100 justify-content-center align-items-center'>
					<Image
						className={`d-flex m-2 mb-3`}
						style={{
							backgroundColor: 'transparent',
							width: "20vh",
							objectFit: 'contain'
						}}
						src={DEFAULT_OLERN_TRANSPARENT_LOGO}
						alt="Olern Logo"
					/>
				</div>
				<div className='d-flex justify-content-center align-items-center'>
					<Paper 
						elevation={3}
						sx={paperStyle}
						className='my-5'
					>
						<div className='d-flex m-2 mb-3 justify-content-center align-items-center'>
							<Image
								className="responsive-qa-logo"
								src={INTLIGUIDE_LOGO}
								alt="Olern Logo"
							/>
						</div>
						
						<Typography 
							className={"font-size-xxl mb-4 " + `${emailSent ? " color-black ": " color-primary "}`}
							style={{
								textAlign: "center"
							}}>
							<b>{emailSent ? "Almost There: Enter Your OTP in ChatGPT" : "Login to your AI PowerTool"}</b>
						</Typography>
						<Formik
							enableReinitialize={true}
							initialValues={{
								"username": "",
							}}
							validate={(values) => {
								const errors = {};

								if(!values.username) {
									errors.username = 'Required';
								}

								return errors
							}}
							onSubmit={(values , {setSubmitting}) => {
								setSubmitting(true)

								if(isEmpty(library_code)){
									displayToast("error", "Library code not found");
									setSubmitting(false)
								}
								
								let params = {
									username : values.username,
									library_code : library_code
								}

								loginGPT(params).then((response) => {
									if(response.data.init[0].status === 'error') {
										if(response.data.init[0]["message"] == "Access is now expired"){
											displayToast("error", response.data.init[0]["message"] + "\n Redirecting you to buy credits");
											setTimeout(() => {
												window.location.href = "https://www.olern.ai";
											}, 10000);
										}
										else {
											displayToast("error", response.data.init[0]["message"]);
											setSubmitting(false)
										}
									}
									else {
										displayToast("success", "OTP has been sent to your email");
										setEmailSent(true)

										setTimer(30)
										let intervalId = setInterval(() => {
											setTimer(timerCount => timerCount - 1);
										}, 1000);

										setTimeout(() => {
											setSubmitting(false)
											clearInterval(intervalId);
										}, 30000);
									}
								});
							}}
						>
							
							{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting
								}) => (
									<Form style={{ width: "100%"}} validate="validate">
										{
											emailSent ? (<>
												<center>
													<TextView className="font-weight-medium font-size-large m-1 mb-5">Please check your email for the OTP. Once you have it, go back ChatGPT and enter the OTP there to begin using the AI PowerTool.  Enjoy!</TextView>
												
													<BaseButton
														className="font-primary font-size-xm bg-primary color-white mt-3 w-75"
														customstyles={{ borderRadius: '30px', height: '3.5rem', width: '15rem' }}
														type="submit"
														issubmitting={isSubmitting}
													>
														{"Resend OTP" + `${(timer > 0) ? ("(" + timer + ") sec") : ""}`}
													</BaseButton>
												</center>
											</>) : (<>
												<BaseTextField
													title="Email"
													name="username"
													variant="outlined"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.username}
													helperText={touched.username && errors.username}
													error={Boolean(touched.username && errors.username)}
												/>
												<center>
													<BaseButton
														className="font-primary font-size-xm bg-primary color-white mt-3 w-75"
														customstyles={{ borderRadius: '30px', height: '3.5rem', width: '15rem' }}
														type="submit"
														issubmitting={isSubmitting}
													>
														Send OTP to my Email
													</BaseButton>
												</center>
											</>)
										}
									</Form>
								)
							}	
						</Formik>
					</Paper>
				</div>
				
				<div className='d-flex flex-column mt-4 w-100 overflow-x-auto'>
					<div className='mt-4 mb-3 font-weight-heavy'>
						<center>
							Get FREE <a href={process.env.REACT_APP_WP_URL} target='_blank'>Trainings for SMEs!</a>
						</center>
					</div>
				</div>
			</div>
		</div>
	</>);
};

export default GPTLogin;