import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const BaseButton = ({...props}) => {
	let variant = "";
	let style = "";
	let disabled = props.issubmitting ? "disabled" : "";

	switch(props.variant) {
		case "abort":
			variant = "outlined";
			style = "font-size-xm color-secondary bg-white outline-secondary"
			break;
		case "undo":
			variant = "outlined";
			style = "font-size-xm color-secondary bg-senary"
			break;
		case "small":
			variant = "outlined";
			style = "font-size-xm theme-base-button-sm bg-senary";
			break;
		case "medium":
			variant = "outlined";
			style = "font-size-xm theme-base-button-md color-white bg-primary";
			break;
		case "primary":
			variant = "contained";
			style = "bg-primary color-white font-size-xm";
			break;
		case "red":
		case "green":
			variant = "contained";
			style = "bg-"+ props.variant +" color-white font-size-xm";
			break;
		case "custom":
			variant = "contained";
			style = "font-size-xm";
		default:
			variant = "contained";
			style = "bg-primary font-size-xm";
	}

	return (
		<Button 
			style={{textTransform: 'none', ...props.customstyles }}
			className={`theme-base-button font-primary ${style} ${disabled} ${props.customclasses}`}
			variant={variant}
			disabled={ props.issubmitting || props.disabled}
			{...props}
		>
			{
				props.issubmitting && (
					<CircularProgress
						className="me-2"
						sx={{
							color: "white"
						}}
						size={20}
						thickness={7}
					/>
				)
			}
			{ props.children }
		</Button>
	)
}

export default BaseButton;